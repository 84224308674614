.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100vh;
}

.spinner {
  width: 54px;
  height: 54px;
  border: 8px solid;
  border-color: #aaa transparent #aaa transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
