* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --darkGreenColor: #00200f;
  --mainGreenColor: #006430;
  --white: #fff;
  --lightGray: #d9d9d9;
  --textGray: #787a80;
}

html {
  font-size: 100%;
  box-sizing: inherit;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  font-size: 18px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  width: 100%;
  height: auto;
}

.relative {
  position: relative;
}
.sup {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: yellow;
  color: #111;
  padding: 0 4px;
  border-radius: 10px;
  font-size: 14px;
}

/* button  */
.no_btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.btn_style {
  border: 1px solid var(--mainGreenColor);
  background-color: var(--mainGreenColor);
  color: #ffffff;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.btn_style:hover {
  background-color: transparent;
  color: var(--mainGreenColor);
}

/* Header  */
.top_head {
  position: sticky;
  top: 0;
  z-index: 999;
  display: block;
}
.topbar {
  background-color: #111;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.topbar .topbar_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.topbar .topbar_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.topbar .topbar_right li,
.topbar .topbar_left li {
  padding: 10px;
}
.header {
  color: #fff;
  background-color: #868686 !important;
}
.megamenu {
  position: relative;
}
.megamenu > a {
  font-size: 16px;
}
.megamenu .drop {
  position: absolute;
  top: 45px;
  left: 0;
  background-color: #00200f;
  display: flex;
  flex-direction: column;
  width: 200px;
  display: none;
}
.megamenu:hover .drop {
  display: block;
}
.header_mobile {
  display: none;
}
.header_middle ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_right ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header_right li,
.header_middle li {
  padding: 10px;
}

.header_right li svg,
.header_middle li svg {
  font-size: 26px;
}
.drop_down ul li a,
.drop_down ul li div.mob_drop {
  padding-left: 50px;
}
.sidebar_nav li .mob_drop {
  display: flex;
  justify-content: space-between;
}
.sidebar_nav li .mob_drop svg {
  font-size: 20px;
}
.sidebar_nav li .mob_drop.open svg {
  transform: rotate(90deg);
}
.drop_down ul li .drop a {
  padding-left: 80px;
}

/* Product Section  */
.product_section {
  text-align: center;
  padding: 40px 0;
}
.section_head {
  position: relative;
}
.section_head h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 74px;
  width: 100%;
  margin: 0;
}
.section_head h1 {
  font-size: 24px;
}
.product_section h3 {
  font-size: 26px;
  font-weight: 700;
}
.product_section h3.product-card__title {
  font-size: 18px;
}
.p_img {
  transition: all 0.5s ease;
}
.p_img:hover {
  transform: scale(1.1);
}
.p_img img {
  margin-bottom: 15px;
}

/* Section  */
.section_about {
  padding: 50px 0;
}
.section_text {
  text-align: justify;
}
.product-cards__slider-nav {
  transform: translateX(12px);
}
.product-cards__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: var(--darkGreenColor);
}

.product-cards__link {
  display: inline-flex;
  align-items: center;
  padding: 0 40px;
  border: 1px solid #17696a;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #17696a;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
}
.product-cards__link:hover {
  background-color: #17696a;
  color: #fff;
}
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e8ed;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
}

.out_label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #111;
  color: #fff;
  padding: 2px 8px;
  z-index: 9;
}

.deactive {
  cursor: pointer;
  opacity: 0.7;
}

.product-card__top {
  position: relative;
}
.product-card__image {
  height: 100%;
}
.product-card__image--small img {
  height: 320px;
}
.product-card__image--large img {
  height: auto;
  max-height: 370px;
}
.product-card__info {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}
.product-card__title {
  margin-bottom: 3px;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--mainGreenColor);
}

.product-card__title a {
  color: var(--mainGreenColor);
  font-weight: 600;
}
.product-card__info .delivery_date {
  margin-bottom: 10px;
}

.product-card__props {
  margin: 0 16px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-card__price {
  margin-bottom: 0;
  transition: margin 0.4s ease-in-out;
}
.product-card__selects {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.wishlist__btn {
  background-color: transparent;
  border: none;
  margin: 10px;
  display: flex;
}
.product-card__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mainGreenColor);
  width: 100%;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.product-card__btn:hover {
  background-color: #024b25;
}
.product-card__btn svg {
  display: block;
  object-fit: cover;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  color: inherit;
  fill: currentColor;
}
.product-card__btn.deactive {
  cursor: not-allowed;
  background-color: #2d4d3c;
}
.product-card .sale-badge {
  position: absolute;
  left: 16px;
  top: 16px;
}
.product-card .rating {
  position: absolute;
  top: 16px;
  right: 16px;
}
.product-card .wishlist-badge {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.product-card .price__current {
  font-weight: 700;
  line-height: 130%;
  color: #1e212c;
}
.product-card .price__current--small {
  font-size: 20px;
}
.product-card .price__current--large {
  font-size: 24px;
}
.product-card .price__old {
  font-weight: 400;
  line-height: 150%;
}
.product-card .price__old--small {
  font-size: 16px;
}
.product-card .price__old--large {
  font-size: 18px;
}
.product-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.pr_card {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

/* ============= Footer Style Start ============== */
/* ============================================== */
.footer_brand {
  color: var(--white);
  font-size: 32px;
  font-weight: 600;
}
.main-footer {
  position: relative;
  padding: 110px 0px 0px;
  background-color: #636363;
  background-repeat: repeat-x;
  background-position: right bottom;
}
.main-footer .footer-list li {
  list-style: none;
}

.main-footer .footer-widget {
  position: relative;
  margin-bottom: 40px;
}

.main-footer .widgets-section {
  position: relative;
  padding-bottom: 60px;
}

.main-footer .footer-widget h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: var(--white);
  line-height: 1.2em;
  margin-bottom: 30px;
  margin-top: 25px;
  text-transform: capitalize;
}

.main-footer .about-widget {
  position: relative;
}

.main-footer .about-widget .logo {
  position: relative;
  margin-bottom: 15px;
  width: 210px;
}

.main-footer .about-widget .logo img {
  width: 100%;
}

.main-footer .about-widget .text {
  position: relative;
}

.main-footer .about-widget .text p {
  position: relative;
  color: var(--lightGray);
  font-size: 15px;
  line-height: 1.7em;
  margin-bottom: 20px;
}

.main-footer .about-widget .text p:last-child {
  margin-bottom: 0px;
}

/* Footer List */

.main-footer .footer-list {
  position: relative;
  padding: 0px;
}

.main-footer .footer-list li {
  position: relative;
  margin-bottom: 17px;
}

.main-footer .footer-list li a {
  position: relative;
  color: var(--lightGray);
  font-size: 15px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .footer-list li a:hover {
  text-decoration: underline;
  color: #fff;
}

/*Gallery Widget*/

.main-footer .gallery-widget {
  position: relative;
  max-width: 350px;
}

.main-footer .gallery-widget .images-outer {
  position: relative;
  margin: 0px -3px;
}

.main-footer .gallery-widget .image-box {
  position: relative;
  float: left;
  width: 33.333%;
  padding: 0px 5px;
  margin-bottom: 10px;
}

.main-footer .gallery-widget .image-box img {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .gallery-widget .image-box img:hover {
  opacity: 0.7;
}

/* Info List */

.main-footer .info-list {
  position: relative;
  padding: 0px;
}

.main-footer .info-list li {
  position: relative;
  color: var(--lightGray);
  font-size: 15px;
  line-height: 1.8em;
  margin-bottom: 12px;
  list-style: none;
}

.main-footer .info-widget .social-links {
  position: relative;
  float: left;
  padding: 15px 0px;
}

.main-footer .info-widget .social-links li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.main-footer .info-widget .social-links li:last-child {
  margin-right: 0px;
}

.main-footer .info-widget .social-links li a {
  position: relative;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-footer .info-widget .social-links li {
  background-color: var(--white);
  border-radius: 50px;
}

.main-footer .info-widget .social-links li a svg {
  color: var(--mainGreenColor);
  transition: 0.3s ease all;
}
.main-footer .info-widget .social-links li:hover a svg {
  transform: scale(1.3);
}

.main-footer .footer-bottom {
  position: relative;
  padding: 20px 0px;
  border-top: 2px solid var(--white);
}

.main-footer .footer-bottom .footer-nav {
  position: relative;
  text-align: right;
  margin-bottom: 0px;
}

.main-footer .footer-bottom .footer-nav li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  line-height: 1.1em;
  display: inline-block;
  border-right: 1px solid #8a8d91;
}

.main-footer .footer-bottom .footer-nav li:last-child {
  padding-right: 0px;
  margin-right: 0px;
  border-right: none;
}

.main-footer .footer-bottom .footer-nav li a {
  position: relative;
  color: var(--white);
  font-size: 15px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .footer-bottom .footer-nav li a:hover {
  color: var(--mainGreenColor);
  text-decoration: underline;
}
.copyright {
  color: var(--white);
}

/* sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #00200f;
  width: 100%;
  z-index: 99;
  color: #fff;
  transition: all 0.5s ease-in-out;
  transform: translateY(-110%);
  overflow-y: scroll;
}
.sidebar.active {
  transform: translateY(0);
}
.sidebar_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 15px;
  background: #fff;
}
.sidebar_header img {
  max-width: min(200px, 80%);
}
.sidebar_header svg {
  color: #111;
  font-size: 32px;
}
.sidebar_nav {
  overflow: scroll;
  height: calc(100vh - 80px);
}
.sidebar_nav li a,
.sidebar_nav li div {
  padding: 8px 20px;
  display: block;
  border-bottom: 1px solid #414b41;
  font-size: 16px;
}
.sidebar_nav > ul > li:last-child a {
  border: none;
}
/* /sidebar */

/* contact us page */
.contact_card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 1px 2px 12px rgba(204, 204, 204, 0.752);
}
.contact_card h1 {
  font-size: 28px;
  color: var(--mainGreenColor);
}
.contact_card form label {
  font-size: 18px;
  padding-bottom: 6px;
}
.contact_page .contact_info {
  position: relative;
  padding: 0px;
}

.contact_page .contact_info li {
  position: relative;
  color: var(--darkGreenColor);
  font-size: 15px;
  line-height: 1.8em;
  margin-bottom: 12px;
  list-style: none;
}
.contact_page .contact_info li svg {
  font-size: 18px;
  color: var(--mainGreenColor);
}

.contact_page .social_links {
  position: relative;
  padding: 15px 0px;
}

.contact_page .social_links li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.contact_page .social_links li:last-child {
  margin-right: 0px;
}

.contact_page .social_links li a {
  position: relative;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_page .social_links li {
  background-color: var(--mainGreenColor);
  border-radius: 50px;
}

.contact_page .social_links li a svg {
  color: var(--white);
  transition: 0.3s ease all;
}
.contact_page .social_links li:hover a svg {
  transform: scale(1.3);
}

/* search */
.search {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #00200f;
  width: 100%;
  z-index: 999;
  color: #fff;
  transition: all 0.5s ease-in-out;
  transform: translateY(-110%);
  overflow-y: scroll;
}
.search.active {
  transform: translateY(0);
}
.search_sug {
  max-width: 500px;
  margin: auto;
  padding: 10px;
}
.search_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_box input {
  margin-right: 10px;
}
.search_box button svg {
  font-size: 20px;
}
.suggest {
  height: 300px;
  overflow-y: auto;
}
.suggest li a {
  color: #111;
  background-color: #fff;
  width: 100%;
  padding: 5px 10px;
  display: block;
  border: 1px solid #111;
}

.cat_choice {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: left;
}
.cat_choice .p_category h3 {
  text-transform: uppercase;
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.cat_choice .p_category ul {
  padding-left: 20px;
}
.cat_choice .p_category ul li .cat_choice_list {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cat_choice .p_category ul .cat_choice_list a {
  font-size: 18px;
  font-weight: 600;
}
.cat_choice .p_category ul li .cat_choice_list svg {
  border-radius: 50%;
}
.cat_choice .p_category .subdrop li {
  margin-bottom: 10px;
}

/* coffee page */
.coffee_page img {
  max-width: 400px;
}

/* FAQ container */
.faq-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* FAQ list */
.faq-list {
  list-style: none;
  padding: 0;
}

/* FAQ item */
.faq-item {
  margin-bottom: 20px;
}

/* FAQ question */
.faq-question {
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

/* FAQ answer */
.faq-answer {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: left;
}

/* Icon for expanded/collapsed state */
.faq-question:before {
  content: "+";
}

.faq-question.expanded:before {
  content: "-";
}
.p_details h3 {
  font-size: 18px;
}
/* .star-rating .star.full {
  color: rgb(255, 146, 3);
}
.star-rating .star.empty {
  border: 1px solid #ccc;
} */

.star-rating {
  font-size: 24px;
}

.star {
  color: #ffd700;
}

.full:before {
  content: "\2605";
}

.half:before {
  content: "\2605";
  position: relative;
  display: inline-block;
  color: transparent;
  -webkit-text-stroke: 1px #ffd700;
  -webkit-text-fill-color: transparent;
}

.empty:before {
  content: "\2606";
}

/* cart page */
.cart_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 4px;
}
.cart_item img {
  width: 100px;
}
.cart_item .quantity {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.cart_item .quantity input {
  width: 40px;
  margin: 0 10px;
  text-align: center;
}
.cart_item .quantity .qty_minus,
.cart_item .quantity .qty_plus {
  font-size: 20px;
  cursor: pointer;
  background-color: #00200f;
  color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart_item .cart_price {
  font-weight: 600;
  font-size: 20px;
}
.cart_right .cart_item {
  flex-direction: column;
  align-items: flex-start;
}
.cart_item_name {
  font-size: 18px;
}

.shipping_form {
  width: 100%;
}

.popupsection {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  display: grid;
  place-items: center;
}
.popup {
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  min-width: 350px;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 99;
}
.popup_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popup_header h3 {
  font-size: 18px;
}

.popup_header svg,
.popup_header i {
  font-size: 20px;
  cursor: pointer;
}
.orders_list {
  box-shadow: 1px 4px 8px #969696;
  padding: 20px;
  /*  max-width: 400px; */
  margin: 10px auto;
}
.order_card {
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.order_card a {
  display: block;
  width: 100%;
  padding: 10px;
  transition: all 0.25s ease-in-out;
}
.order_card a:hover {
  background-color: #ececec;
}
.order_card h4 {
  font-size: 18px;
}
.container-1 h1 {
  font-size: 32px;
}
/* order details */
.order_details_card {
  border: 1px solid #ccc;
  box-shadow: 2px 4px 12px #ccc;
  border-radius: 8px;
  padding: 10px;
}
.order_product_list {
  border: 1px solid #ccc;
  box-shadow: 2px 4px 12px #ccc;
  border-radius: 8px;
  padding: 10px;
}
.order_details_card .order_product_list {
  border: 1px solid #ccc;
  box-shadow: 2px 4px 12px #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
}
.order_details_card h2 {
  font-size: 24px;
}
.order_product_list h4 {
  font-size: 20px;
}
.section_header h2 {
  font-size: 24px;
}

/* admin pages */
.admin_order_card {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 20px 0;
}
.admin_order_card h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.react-datepicker-wrapper {
  width: 100%;
}

/* media Query globally */
@media only screen and (max-width: 1023px) {
  .copyright {
    text-align: center;
    color: var(--lightGray);
  }
  .main-footer .footer-bottom .footer-nav {
    text-align: center;
    padding: 0px;
  }
  .main-footer .footer-bottom .footer-nav li:last-child {
    color: var(--lightGray);
  }
}
@media (max-width: 769px) {
  .product_section h3 {
    font-size: 12px;
  }
  .cart_item {
    flex-direction: column;
  }
  .top_head {
    display: none;
  }
  .header_mobile {
    display: block;
  }
  .section_head h2 {
    font-size: 24px;
  }
  .header_mobile svg {
    font-size: 28px;
  }
  .header_mobile svg.search_icon {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  .logo_mobile {
    max-width: 105px;
  }
}
